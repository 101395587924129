// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  AUTH_BASE_URL: 'https://apac-s3wrapper.capillarytech.com',
  API_BASE_URL:'https://apac-apigateway.capillarytech.com/mobile/v2/api',
  SALESFORCE_BASE_URL:' https://abbott-ph-api-dev.sttarter.com/api/v1',
  CMS_URL:'https://api.sttarter.com/contentsystem/d3eaf7a8067373ea08c47134748d7abf',
  API_URl_TRACEBILITY: "https://abbott-ph-api-dev.sttarter.com/api",
  IMAGE_UPLOAD:"https://apac-apigateway.capillarytech.com/mobile/v2/",
  EMAIL_CHECK_API: "https://abbott-api-uat.sttarter.com/api/v1/",
  BRAND: 'ABBOTTDEMO',
  DEVICE_ID: '1234551231',

  IDENTIFIER_TYPE:"MOBILE",
  TILL_ID:"abbottphtemppage",

  NUTRITION_CUST_ADD_URL: 'https://cap-intouch-in.sttarter.com',
  NUTRITION_TILL_ID:'nutrition',
  ga:'G-4YVRTH165J',
  
  ProductLst :{
    products: [{
        product_sku: "Y523.140R6",
        product_name:"Glucerna Chocolate 400g",
        points_to_be_earned: 50
      },
      {
        product_sku: "Y495.152R6",
        product_name:"Glucerna Vanilla 900g",
        points_to_be_earned: 125
      },
      {
        product_sku: "Y495.140R6",
        product_name:"Glucerna Vanilla 400g",
        points_to_be_earned: 50
      },
      {
        product_sku: "S434.140CR6QR",
        product_name:"Glucerna Vanilla 400g",
        points_to_be_earned: 50
      },
      {
        product_sku: "S434.152FR6QR",
        product_name:"Glucerna Vanilla 900g",
        points_to_be_earned: 125
      },
      {
        product_sku: "S435.140DR6QR",
        product_name:"Glucerna Chocolate 400g",
        points_to_be_earned: 50
      }
    ]
  },

//   //Signup 50 Voucher
//   seriescode1:"9561",

// //Signup 100 Voucher
//   seriescode2:"9560",

// //Sudexo 200 Voucher
//   seriescode3:"9562",


  signUpVoucherList:{
    signupvouchers: [{
        seriescode: "9560",
        existing_sf_user_flag: "true",
        title: "Php 100 sodexo voucher",
        description: "For your next Glucerna SR purchase.",
        image: "filename",
        voucher_name:"SIGN UP REWARD"
      },
      {
        seriescode: "9561",
        existing_sf_user_flag: "false",
        title: "Php 50 sodexo voucher",
        description: "For your next Glucerna SR purchase.",
        image: "filename",
        voucher_name:"SIGN UP REWARD"
      }
    ]
  }
}


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
